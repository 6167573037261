import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import localization from '../../localization'
import LegalNav from './LegalNav'

const LegalPage = ({ language }) => {
    return (
        <>
            <SEO
                title={localization[language].terms.terms}
                description={localization[language].terms.terms}
            />
            <Layout isReportPage>
                <LegalNav language={language} />
            </Layout>
        </>
    )
}

export default React.memo(LegalPage)
