import React from 'react'
import Legal from '../../components/Legal'

const Page = ({ location }) => {
    return (
        <Legal
            language="en"
            location={location}
        />
    )
}

export default Page
