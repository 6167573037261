import React from 'react';
import classNames from 'classnames';
import Footer from '../footer';
import Header from '../Header';

import CookieConsent from '../CookieConsent';
import { useAppSelector } from '../../hooks';
import {
    container,
    useReportPage,
    contentWrapper,
    content,
} from './index.module.scss';

interface Props {
    children: any;
    isFrontpage?: boolean;
    logo?: string | null;
    isReportPage?: boolean;
    hideLogo?: boolean;
}

const Layout = ({
    children,
    isFrontpage = false,
    logo = null,
    isReportPage = false,
    hideLogo = false,
}: Props) => {
    const language = useAppSelector((state) => state.global.language);

    return (
        <div className={classNames(container, isReportPage && useReportPage)}>
            <Header
                isFrontpage={isFrontpage}
                isReportPage={isReportPage}
                logo={logo}
                hideLogo={hideLogo}
            />
            {/* <div> */}
            <CookieConsent language={language} />
            {/* </div> */}
            <div className={contentWrapper}>
                <div className={content}>{children}</div>
            </div>

            <Footer isReportPage={isReportPage} />
        </div>
    );
};

export default Layout;
