// extracted by mini-css-extract-plugin
export var account = "index-module--account--ZC8SP";
export var btnMenu = "index-module--btnMenu--Xp7MV";
export var buttons = "index-module--buttons--6NkbT";
export var customLogo = "index-module--customLogo--CLc2q";
export var desktopNav = "index-module--desktopNav--bpnMX";
export var getStartedBtn = "index-module--getStartedBtn--wF43i";
export var header = "index-module--header--Uq0E6";
export var isActive = "index-module--isActive--Ujjup";
export var isMobileMenuOpen = "index-module--isMobileMenuOpen--safDA";
export var isMobileMenuVisible = "index-module--isMobileMenuVisible--bbhaz";
export var isReportPage = "index-module--isReportPage--slIyl";
export var langActions = "index-module--langActions--etlc4";
export var links = "index-module--links--uayCS";
export var mobileMenu = "index-module--mobileMenu--c+mLg";
export var mobileNav = "index-module--mobileNav--FBLWM";
export var nav = "index-module--nav--yPtSz";
export var userActions = "index-module--userActions--K48vF";