import { Link } from 'gatsby'
import React from 'react'
import classNames from 'classnames'
import * as style from './index.module.scss'

const legalPages = {
    is: [
        {
            name: 'Notkunarskilmálar',
            path: 'terms-of-use',
        },
        {
            name: 'Persónuverndarstefna',
            path: 'privacy-policy',
        },
        {
            name: 'Vefkökur',
            path: 'cookie-policy',
        },
    ],
    en: [
        {
            name: 'Terms of Use',
            path: 'terms-of-use',
        },
        {
            name: 'Privacy Policy',
            path: 'privacy-policy',
        },
        {
            name: 'Cookie Policy',
            path: 'cookie-policy',
        },
    ]
}

const LegalNav = ({
    location,
    language,
}) => {
    return (
        <div className={style.nav}>
            {legalPages[language].map((page, index) => (
                <React.Fragment key={page.path}>
                    {index > 0 && (
                        <div className={style.separator}>/</div>
                    )}
                    <div
                        className={classNames(
                            location != null && location.pathname != null && location.pathname.startsWith(`/${language}/legal/${page.path}`) && style.isActive,
                        )}
                    >
                        <Link
                            to={`/${language}/legal/${page.path}`}
                        >
                            {page.name}
                        </Link>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

export default React.memo(LegalNav)
