import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { useMatch } from '@reach/router';
import classNames from 'classnames';
import localization from '../../localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faUser } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { languageSet } from '../../state/reducers/globalReducer';
import * as style from './index.module.scss';

const isBrowser = typeof window !== 'undefined';

interface Props {
    isFrontpage: boolean;
    logo: string | null;
    isReportPage: boolean;
    hideLogo: boolean;
}

const Header = ({ isFrontpage, logo, isReportPage, hideLogo }: Props) => {
    const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);
    const [isMobileNavVisible, setIsMobileNavVisible] = React.useState(false);
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const isSigningIn = useAppSelector((state) => state.global.isSigningIn);
    const language = useAppSelector((state) => state.global.language);
    const hasUser = useAppSelector((state) => state.global.user !== null);
    const organisation = useAppSelector((state) => state.global.organisation);
    const user = useAppSelector((state) => state.global.user);
    const matchPage = useMatch(`/:lang/*`);

    const dispatch = useAppDispatch();

    const toggleMobileMenu = () => {
        if (isMobileNavVisible) {
            setIsMobileNavVisible(false);
        } else if (!isMobileNavOpen) {
            setIsMobileNavOpen(true);
        }
    };

    // const setLanguage = useCallback(
    //     (language) =>
    //         dispatch({
    //             type: `LANGUAGE_SET`,
    //             language,
    //         }),
    //     [dispatch]
    // );

    useEffect(() => {
        let isRemoved = false;
        if (isMobileNavOpen) {
            setTimeout(() => {
                if (!isRemoved) {
                    setIsMobileNavVisible(true);
                }
            }, 60);
        }

        return () => {
            isRemoved = true;
        };
    }, [isMobileNavOpen]);

    useEffect(() => {
        let isRemoved = false;

        const clickOutside = () => {
            setIsMobileNavVisible(false);
        };

        if (!isMobileNavVisible) {
            document.removeEventListener('click', clickOutside, false);
            setTimeout(() => {
                if (!isRemoved) {
                    setIsMobileNavOpen(false);
                }
            }, 350);
        } else {
            document.addEventListener('click', clickOutside, false);
        }

        return () => {
            isRemoved = true;
            document.removeEventListener('click', clickOutside, false);
        };
    }, [isMobileNavVisible]);

    useEffect(() => {
        if (matchPage != null && matchPage.lang != null) {
            if (matchPage.lang === 'is' || matchPage.lang === 'en') {
                dispatch(languageSet(matchPage.lang));
                // setLanguage(matchPage.lang);
            }
        }
    }, [matchPage]);

    const hideSignIn =
        matchPage != null &&
        matchPage['*'] != null &&
        matchPage['*'] === 'signin';

    const onToggleLanguage = () => {
        let queryParams = '';
        if (
            isBrowser &&
            window.location != null &&
            window.location.search != null
        ) {
            queryParams = window.location.search;
        }
        if (matchPage != null) {
            if (matchPage.lang != null) {
                if (matchPage.lang.toLowerCase() === 'is') {
                    navigate(`/en/${matchPage['*']}${queryParams}`);
                } else {
                    navigate(`/is/${matchPage['*']}${queryParams}`);
                }
            } else {
                navigate(`/en/${matchPage['*']}${queryParams}`);
            }
        }

        // if (language === 'is') {
        //     setLanguage('en')
        // } else {
        //     setLanguage('is')
        // }
        // navigate(``)
    };

    const showSignIn = !isSignedIn && !hasUser;
    // console.log(showSignIn, isSignedIn, isSigningIn, hasUser)
    const name = !showSignIn ? (
        <>
            <FontAwesomeIcon icon={faUser} fixedWidth />
            <small> {user?.name || user?.email || ''}</small>
        </>
    ) : (
        localization[language].signin.labelSignIn
    );

    if (isReportPage) {
        return (
            <header className={classNames(style.header, style.isReportPage)}>
                {!hideLogo && (
                    <Link
                        to={`/${language}/report`}
                        title={
                            organisation != null
                                ? organisation.name || 'Tilkynna'
                                : 'Tilkynna'
                        }
                    >
                        <img
                            className={logo != null ? style.customLogo : null}
                            src={logo || '/tlogo.png'}
                            alt='Tilkynna'
                        />
                    </Link>
                )}
                <div className={style.buttons}>
                    {!isFrontpage && (
                        <button
                            type='button'
                            className='btn outline small'
                            onClick={onToggleLanguage}
                            title={
                                language === 'is'
                                    ? 'Switch to English'
                                    : 'Velja Íslensku'
                            }
                            value={language === 'is' ? 'EN' : 'IS'}
                        >
                            {language === 'is' ? 'EN' : 'IS'}
                        </button>
                    )}
                </div>
            </header>
        );
    }

    return (
        <header className={classNames(style.header, style.isMainPage)}>
            <div className={style.mobileNav}>
                {!hideLogo && (
                    <Link
                        to={`/`}
                        title={
                            organisation != null
                                ? organisation.name || 'Tilkynna'
                                : 'Tilkynna'
                        }
                    >
                        <img
                            className={logo != null ? style.customLogo : null}
                            src={logo || '/tlogo.png'}
                            alt='Tilkynna'
                        />
                    </Link>
                )}
            </div>
            <button className={style.btnMenu} onClick={toggleMobileMenu}>
                <FontAwesomeIcon
                    icon={
                        !isMobileNavOpen || !isMobileNavVisible
                            ? faBars
                            : faTimes
                    }
                />
            </button>
            <div className={style.desktopNav}>
                {!hideLogo && (
                    <Link
                        to={`/`}
                        title={
                            organisation != null
                                ? organisation.name || 'Tilkynna'
                                : 'Tilkynna'
                        }
                    >
                        <img
                            className={logo != null ? style.customLogo : null}
                            src={logo || '/tlogo.png'}
                            alt='Tilkynna'
                        />
                    </Link>
                )}
                {isFrontpage && (
                    <div className={style.links}>
                        <Link
                            to={`/features`}
                            className={classNames(
                                matchPage != null &&
                                    matchPage.lang != null &&
                                    matchPage.lang === 'features' &&
                                    style.isActive
                            )}
                        >
                            Eiginleikar
                        </Link>
                        <Link
                            to={`/pricing`}
                            className={classNames(
                                matchPage != null &&
                                    matchPage.lang != null &&
                                    matchPage.lang === 'pricing' &&
                                    style.isActive
                            )}
                        >
                            Áskriftarleiðir
                        </Link>
                        <Link
                            to={`/about-us`}
                            className={classNames(
                                matchPage != null &&
                                    matchPage.lang != null &&
                                    matchPage.lang === 'about-us' &&
                                    style.isActive
                            )}
                        >
                            Um okkur
                        </Link>
                    </div>
                )}

                {!hideSignIn && (
                    <div className={style.userActions}>
                        {showSignIn ? (
                            <Link
                                to={`/${language}/signin`}
                                className='btn small'
                            >
                                {!hasUser && isSigningIn ? (
                                    <>
                                        {localization[language].signin.title}{' '}
                                        <FontAwesomeIcon
                                            icon={faSpinnerThird}
                                            fixedWidth
                                            spin
                                        />
                                    </>
                                ) : (
                                    localization[language].signin.title
                                )}
                            </Link>
                        ) : (
                            <>
                                {user != null && user.isEmailVerified && (
                                    <Link
                                        to='/account/overview'
                                        className='btn small'
                                    >
                                        {name}
                                    </Link>
                                )}
                            </>
                        )}
                    </div>
                )}

                {!isFrontpage && (
                    <div className={style.langActions}>
                        <button
                            type='button'
                            className='btn outline small'
                            onClick={onToggleLanguage}
                            title={
                                language === 'is'
                                    ? 'Switch to English'
                                    : 'Velja Íslensku'
                            }
                            value={language === 'is' ? 'EN' : 'IS'}
                        >
                            {language === 'is' ? 'EN' : 'IS'}
                        </button>
                    </div>
                )}
            </div>

            <div
                className={classNames(
                    style.mobileMenu,
                    isMobileNavOpen && style.isMobileMenuOpen,
                    isMobileNavVisible && style.isMobileMenuVisible
                )}
            >
                <div className={style.nav}>
                    <div className={style.links}>
                        <Link
                            to={`/features`}
                            className={classNames(
                                matchPage != null &&
                                    matchPage.lang != null &&
                                    matchPage.lang === 'features' &&
                                    style.isActive
                            )}
                        >
                            Eiginleikar
                        </Link>
                        <Link
                            to={`/pricing`}
                            className={classNames(
                                matchPage != null &&
                                    matchPage.lang != null &&
                                    matchPage.lang === 'pricing' &&
                                    style.isActive
                            )}
                        >
                            Áskriftarleiðir
                        </Link>
                        <Link
                            to={`/about-us`}
                            className={classNames(
                                matchPage != null &&
                                    matchPage.lang != null &&
                                    matchPage.lang === 'about-us' &&
                                    style.isActive
                            )}
                        >
                            Um okkur
                        </Link>
                    </div>

                    {!isFrontpage && (
                        <div className={style.langActions}>
                            <button
                                type='button'
                                className='btn outline small'
                                onClick={onToggleLanguage}
                                title={
                                    language === 'is'
                                        ? 'Switch to English'
                                        : 'Velja Íslensku'
                                }
                                value={language === 'is' ? 'EN' : 'IS'}
                            >
                                {language === 'is' ? 'EN' : 'IS'}
                            </button>
                        </div>
                    )}
                    {!hideSignIn && (
                        <div className={style.userActions}>
                            {showSignIn ? (
                                <Link
                                    to={`/${language}/signin`}
                                    className='btn outline'
                                >
                                    {!hasUser && isSigningIn ? (
                                        <>
                                            {
                                                localization[language].signin
                                                    .title
                                            }{' '}
                                            <FontAwesomeIcon
                                                icon={faSpinnerThird}
                                                fixedWidth
                                                spin
                                            />
                                        </>
                                    ) : (
                                        localization[language].signin.title
                                    )}
                                </Link>
                            ) : (
                                <>
                                    {user != null && user.isEmailVerified && (
                                        <Link
                                            to='/account/overview'
                                            className='btn outline'
                                        >
                                            {name}
                                        </Link>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default React.memo(Header);
